export default {
    public: require('./public').default, //公用接口
    download: require('./download').default, //客户端下载
    help: require('./help').default, //帮助中心
    user: require('./user').default, //用户管理
    role: require('./role').default, //角色管理
    substance: require('./substance').default, //内容中心/素材管理
    dealer: require('./dealer').default, //经销商管理
    task: require('./task').default, //任务管理
    clew: require('./clew').default, //线索管理
    organization: require('./organization').default, //组织架构
    report: require('./report').default, //分析报表
    vision: require('./vision').default, //抖快视界
    subject: require('./subject').default, //专题设计
    hotpoint: require('./hotpoint').default, //热点植入
    aisbot: require('./aisbot').default, //在线客服
    aicreate: require('./aicreate').default, //AI图文
    aivideo: require('./aivideo').default, //AI视频
    aivideotemplete: require('./aivideotemplete').default, //AI视频模板
}
