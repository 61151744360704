export default {
    //AI视频-主题列表
    GET_AITHEME_LIST: '/api/aivideos/theme/list',
    //根据主题ID获取主题详情-混剪和脚本视频公用
    GET_THEME_BYID: '/api/aivideos/theme/info',
    //根据主题id获取视频列表
    GET_VIDEOS_BTTHEMEID: '/api/aivideos/getVideoListByThemeid',

    //AI视频-创建AI视频文案-混剪
    CREATE: '/api/aivideos/theme/create',
    //根据主题id获取文章列表
    GET_AIARTICLE_BTTHEMEID: '/api/aivideos/getSeedListByThemeid',
    //创建视频
    CREATE_AI_VIDEO: '/api/aivideos/aiVideoCreateByMontage',
    //添加素材
    ADD_VIDEO_RESOURCES: '/api/aivideos/addResources',
    //获取素材
    GET_VIDEO_RESOURCES: '/api/aivideos/resourcesAjax',
    //删除素材
    DEL_VIDEO_RESOURCES: '/api/aivideos/delResources',

    //AI脚本模式主题创建
    CREATE_THEME_LENS: '/api/aivideos/themeLensCreate2',
    //脚本化AI视频创作
    CREATE_AISTORY_VIDEO: '/api/aivideos/aiVideoCreateByLens',
    ROLL_BACK_THEMESTATE: '/api/aivideos/aiVideoFallback',
    //主题id获取镜头原始文案
    GET_DEFAULT_LENS: '/api/aivideos/getLensContentByThemeid',
    //镜头添加
    ADD_LENS: '/api/aivideos/addLens',
    //镜头编辑
    EDIT_LENS: '/api/aivideos/editLens',
    //镜头删除
    DEL_LENS: '/api/aivideos/delLens',
    //根据主题id获取分镜列表
    GET_STORYBORD_BTTHEMEID: '/api/aivideos/getLensByThemeid',
    //获取ai视频账号总数
    GET_ACCOUNT_NUMBER: '/api/aivideos/getAccountNum',

    //AI视频-批量下发经销商
    SET_AITHEME_RELEASE: '/api/aivideos/videosRelease',
    //确认发布
    PUBLISH_DOUYIN: '/api/aivideos/publishDouyin',
    //AI视频-账户中心
    GET_ACCOUNT_LIST: '/api/aivideos/account/list',
    //导出
    GET_ACCOUNT_EXPORT: '/api/export/aivideos/account/list',
    //机构号下拉搜索
    GET_ACCOUNT_ORGAN_LIST: '/api/aivideos/organ/list',
    //账号中心-同步子账户记录
    GET_SYNC_RECORD: '/api/aivideos/account/confer/synclog',
    // 操作-同步
    ACCOUNT_SYNC: '/api/aivideos/account/confer/sync',
    //AI视频-解绑抖音账户
    ACCOUNT_UNBIND: '/api/aivideos/unbindDouyinUser',
    //抖音账号换绑员工
    CHANGE_STAFF: '/api/aivideos/changeStaff',
    //账户中心-多层级展示[批量下发]
    ACCOUNT_PUSH_SELECT: '/api/aivideos/account/publish/select',

    //AI视频-发布记录[作品分析]
    GET_AIPUBLISH_LIST: '/api/aivideos/sendRecords',
    //作品评论列表
    VIDEO_COMMENT_TOTAL: '/api/aivideos/comment/list',
    //作品回复评论
    VIDEO_COMMENT_REPLY: '/api/aivideos/comment/reply',
    //发布记录导出
    EXPORT_AIPUBLISH_LIST: '/api/export/aivideos/sendRecords',


    //AI视频-智能分析
    // GET_AIVIDEO_ANALYZE: '/api/aivideos/analyze',

    // //AI视频-账户创建
    // CREATE_ACCOUNT: '/api/aivideos/account/create',
    // //AI视频-账户登录
    // // ACCOUNT_LOGIN: '/api/aivideos/account/login',
    // //更新账户
    ACCOUNT_UPDATE: '/api/aivideos/account/update',
    // //删除账户
    // ACCOUNT_DELETE: '/api/aivideos/account/delete',
    // //获取账户详情
    // ACCOUNT_DETAIL: '/api/aivideos/account/info',

    //智能分析-数据接口
    // ANALYSIS_DATA: '/api/aivideos/analysis/index',
    // //顾问排行
    // ADVISOR_RANK: '/api/aivideos/analysis/consultantRank',
    // //文章排行
    // ARTICLE_RANK: '/api/aivideos/analysis/articleRank',

    //获取通道用户组
    GET_ISSUETASK_USER_GROUP: '/api/aivideos/getUserGroup',
    //获取通道用户组用户列表
    GET_ISSUETASK_USER_LIST: '/api/aivideos/getUserListBygid',
    //下发协助任务
    PUSH_ISSUETASK: '/api/aivideos/pushAssistTask',

    //获取抖音POI地址
    GET_POI_LIST: '/api/AiVideoSet/getPoiList',
    //保存POI
    SAVE_POI: '/api/AiVideoSet/editPOI',
    //获取通道保存的POI
    GET_POI: '/api/AiVideoSet/getPOI',
    //抖音小程序配置
    SAVE_DOUYIN_APPLET: '/api/AiVideoSet/editAppletByDouYin',
    //获取通道保存的抖音小程序配置
    GET_DOUYIN_APPLET: '/api/AiVideoSet/getAppletByDouYin',
    //抖音小程序配置-------2024-08-14
    douyinAppletConfig: {
      // 抖音小程序-列表
      GET_LIST: '/api/admin/douyin/applet/list',
      // 抖音小程序-新增
      ADD: '/api/admin/douyin/applet/create',
      // 抖音小程序-编辑
      EDIT: '/api/admin/douyin/applet/update',
      // 抖音小程序-删除
      DELETE: '/api/admin/douyin/applet/delete',
      // 获取下拉选择列表
      GET_SELECT_LIST: '/api/admin/douyin/applet/select'
    },
    //挂载小程序
    APPLET_BIND: '/api/aivideos/account/applet/bind',

    //删除主题
    DELETE_AIVIDEO_THEME: '/api/aivideos/delTheme',
    //恢复删除的主题
    RECOVER_AIVIDEO_THEME: '/api/aivideos/recoverTheme',
    //获取音乐音效标签类型
    GET_ALI_MUSIC_TAGS: '/api/aivideos/getAiVideoTags',
    //获取音乐音效列表
    GET_ALI_MUSIC_LIST: '/api/aivideos/getMediaLists',

    //视频-标题/旁白文案差异化
    vrich: {
        //AI创作-获取标题列表
        TITLE_LIST: '/api/aivideos/getTitleByThemeid',
        //AI生成标题 动作模式 get:获取 product:生产
        PRODUCT_TITLE: '/api/aivideos/themeTitleAiauto',
        //修改、使用标题 动作模式 plus:新增（默认） cover:覆盖
        USE_PRODUCT_TITLE: '/api/aivideos/themeTitleUpdate',
        //AI生成镜头文案
        PRODUCT_LENS: '/api/aivideos/themeLensAiauto',
        //AI视频脚本模式-生成组合的文案
        PRODUCT_AI_THEME: '/api/aivideos/themeLensCopywriter',
    }
}
