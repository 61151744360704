const homeRouter = [
    {
        //内容中心->包括【内容中心4，专题设计6，热点植入16，任务中心8，素材管理5，分析报表9】
        path: 'substance',
        name: 'substance',
        component: () => import(/* webpackChunkName: "home/substance/index" */ '../views/home/substance/index/index.vue'),
        meta: {
            authkey: ["4-1","4-2","4-3","4-4","4-5","4-6","6-1","16-1","16-2","16-3","16-4","16-5","16-6","8-1","8-2","8-3","8-4","5-1","5-2","5-3","5-4","5-5","5-6","9-1","9-2","9-3","13-2"]
        }
    },
    {
        //内容中心--下发列表
        path: 'substance/send',
        name: 'substanceSend',
        component: () => import(/* webpackChunkName: "home/substance/send" */ '../views/home/substance/send/index.vue'),
    },
    {
        //内容中心--内容审核
        path: 'substance/verify',
        name: 'substanceVerify',
        component: () => import(/* webpackChunkName: "home/substance/verify" */ '../views/home/substance/verify/index.vue'),
        meta: {
            authkey: ["4-5"]
        }
    },
    {
        //专题制作
        path: 'subject',
        name: 'subject',
        component: () => import(/* webpackChunkName: "home/subject" */ '../views/home/subject/index.vue'),
        props: (route) => ({ id: route.query.id }),
        meta: {
            authkey: ["6-1"]
        }
    },
    //--------------------------------------------
    {
        //任务中心
        path: 'task',
        name: 'task',
        component: () => import(/* webpackChunkName: "home/task" */ '../views/home/task/index.vue'),
        meta: {
            authkey: ["8-1","8-2","8-3","8-4"]
        }
    },
    {
        //任务详情
        path: 'task/detail',
        name: 'taskDetail',
        component: () => import(/* webpackChunkName: "home/task" */ '../views/home/task/detail/index.vue'),
        meta: {
            authkey: ["8-1"]
        }
    },
    {
        //经销商任务详情
        path: 'task/dealer/detail',
        name: 'taskDealerDetail',
        component: () => import(/* webpackChunkName: "home/task" */ '../views/home/task/dealer/detail/index.vue'),
        meta: {
            authkey: ["8-1"]
        }
    },
    {
        //经销商端  任务中心 详情
        path: 'task/dealer/center/detail',
        name: 'taskDealerCenterDetail',
        component: () => import(/* webpackChunkName: "home/task" */ '../views/home/task/dealer/center/detail/index.vue'),
        meta: {
            authkey: ["8-1"]
        }
    },
    //--------------------------------------------
    //抖快视界 vision/field->已更名为"视频分析"放在AI视频模块下
    {
        path: 'vision/field',
        name: 'visionField',
        component: () => import(/* webpackChunkName: "home/vision/field" */ '../views/home/vision/field/index.vue'),
        meta: {
            authkey: ["15-11"]
            // authkey: ["12-1","12-2","12-3","12-4"]
        }
    },
    //热点植入 hot/index/index->变更放在创意资产模块下
    {
        path: 'hot/index/index',
        name: 'hotPointIndex',
        component: () => import(/* webpackChunkName: "home/hot/index/index" */ '../views/home/hot/index/index.vue'),
        meta: {
            authkey: ["16-1","16-2","16-3","16-4","16-5","16-6"]
        }
    },
    //AI图文 ai/product
    {
        path: 'ai',
        name: 'aiProduct',
        component: () => import(/* webpackChunkName: "home/ai" */ '../views/home/ai/index.vue'),
        meta: {
            authkey: ["14-1","14-2","14-3","14-4","14-5","14-6","14-7","14-8","14-9","14-10","14-11"]
        }
    },
    {
        path: 'aidemo',
        name: 'aiProductdemo',
        component: () => import(/* webpackChunkName: "home/ai" */ '../views/home/ai/aidemo.vue'),
        meta: {
            authkey: ["14-1","14-2","14-3","14-4","14-5","14-6","14-7","14-8","14-9","14-10","14-11"]
        }
    },
    //AI视频 mixedshear/product
    {
        path: 'mixedshear',
        name: 'mixedshear',
        component: () => import(/* webpackChunkName: "home/ai" */ '../views/home/mixedshear/index.vue'),
        meta: {
            authkey: ["15-1","15-2","15-3","15-4","15-5","15-6","15-7","15-8","15-9","15-10","15-11","15-12"]
        }
    },
    {
        //AI视频模板制作
        path: 'mixedshear/create',
        name: 'createAiTemplete',
        component: () => import(/* webpackChunkName: "home/subject" */ '../views/home/mixedshear/templateList/create/index.vue'),
        props: (route) => ({ id: route.query.id }),
    },
    {
        //AI视频模板预览
        path: 'mixedshear/package',
        name: 'viewAiTemplete',
        component: () => import(/* webpackChunkName: "home/subject" */ '../views/home/mixedshear/templateList/package/index.vue'),
        props: (route) => ({ id: route.query.id, title: route.query.title }),
    },
    //线索管理 clew
    {
        path: 'clew',
        name: 'clew',
        component: () => import(/* webpackChunkName: "home/clew" */ '../views/home/clew/index.vue'),
        meta: {
            authkey: ["7-1","7-2"]
        }
    },
    //素材管理 material
    {
        path: 'material',
        name: 'material',
        component: () => import(/* webpackChunkName: "home/material/index" */ '../views/home/material/index.vue'),
        meta: {
            authkey: ["5-1","5-2","5-3","5-4","5-5","5-6"]
        }
    },
    {
        //素材管理--下发列表
        path: 'material/send',
        name: 'materialSend',
        component: () => import(/* webpackChunkName: "home/material/send" */ '../views/home/material/send/index.vue'),
        meta: {
            authkey: ["5-6"]
        }
    },
    {
        //素材管理--素材审核
        path: 'material/verify',
        name: 'materialVerify',
        component: () => import(/* webpackChunkName: "home/material/verify" */ '../views/home/material/verify/index.vue'),
        meta: {
            authkey: ["5-5"]
        }
    },
    //------------------------------------------
    //分析报表 report
    {
        path: 'report',
        name: 'report',
        component: () => import(/* webpackChunkName: "home/report" */ '../views/home/report/index.vue'),
        meta: {
            authkey: ["9-1","9-2","9-3","13-2"]
        }
    },
    {
        //素材分析报表->经销商详情
        path: 'report/material/dealer/detail',
        component: () => import(/* webpackChunkName: "home/report" */ '../views/home/report/material/dealer/detail/index.vue'),
    },
    {
        //经销商报表->内容详情
        path: 'report/dealer/material/detail',
        component: () => import(/* webpackChunkName: "home/report" */ '../views/home/report/dealer/material/detail/index.vue'),
    },
    {
        //经销商报表->顾问详情
        path: 'report/dealer/adviser/detail',
        component: () => import(/* webpackChunkName: "home/report" */ '../views/home/report/dealer/adviser/detail/index.vue'),
    },
    {
        //顾问报表->素材详情
        path: 'report/adviser/material/detail',
        component: () => import(/* webpackChunkName: "home/report" */ '../views/home/report/adviser/material/detail/index.vue'),
    },
    //-----------------------------------------
    //在线客服聊天 chat
    {
        path: 'chat',
        name: 'chat',
        component: () => import(/* webpackChunkName: "home/chat" */ '../views/home/chat/index.vue'),
        meta: {
            authkey: ['13-1','13-3','13-4','13-5','13-11'] 
        }
    },
    //在线客服报表 chat 
    {
        path: 'chat/dealer',
        name: 'chatdealer',
        component: () => import(/* webpackChunkName: "home/chat" */ '../views/home/chat/dealer/index.vue'),
        meta: {
            authkey: ['13-2']
        }
    },
    //用户管理 user
    {
        path: 'user',
        name: 'user',
        component: () => import(/* webpackChunkName: "home/user" */ '../views/home/user/index.vue'),
        meta: {
            authkey: ["3-1","3-2","3-3","3-4","3-5","3-6"]
        }
    },
    //角色管理 role
    {
        path: 'role',
        name: 'role',
        component: () => import(/* webpackChunkName: "home/role" */ '../views/home/role/index.vue'),
        meta: {
            authkey: ["2-1","2-2","2-3","2-4"]
        }
    },
    //经销商管理 dealer->门店管理
    {
        path: 'dealer',
        name: 'dealer',
        component: () => import(/* webpackChunkName: "home/dealer" */ '../views/home/dealer/index.vue'),
        meta: {
            authkey: ["1-1","1-2","1-3","1-4"]
        }
    },
    //组织架构 organization
    {
        path: 'organization',
        name: 'organization',
        component: () => import(/* webpackChunkName: "home/organization" */ '../views/home/organization/index.vue'),
        meta: {
            authkey: ["10-2","1-3","10-4"]
        }
    },
    //个人中心 self/center
    {
        path: 'self/center',
        name: 'selfCenter',
        component: () => import(/* webpackChunkName: "home/self/center" */ '../views/home/self/center/index.vue'),
    },
    //收藏列表 favorite/list
    {
        path: 'favorite/list',
        name: 'favoriteList',
        component: () => import(/* webpackChunkName: "home/favorite/list" */ '../views/home/favorite/list/index.vue'),
    },
    //帮助中心 help/center
    {
        path: 'help/center',
        name: 'helpCenter',
        component: () => import(/* webpackChunkName: "home/help/center" */ '../views/home/help/center/index.vue'),
    },
    //系统配置
    {
        path: 'system/config',
        name: 'systemConfig',
        component: () => import(/* webpackChunkName: "home/system/config" */ '../views/home/system/config/index.vue'),
        props: (route) => ({ tabid: route.query.tabid }),
        meta: {
            authkey: ["11-1","11-2","11-3"]
        }
    },
    // 角色用户 roleAndUser
    {
        path: 'roleAndUser',
        name: 'roleAndUser',
        component: () => import(/* webpackChunkName: "home/roleAndUser" */ '../views/home/roleAndUser/index.vue'),
        meta: {
            authkey: ["2-1","2-2","2-3","2-4","3-1","3-2","3-3","3-4","3-5"]
        }
    },
    // 门店管理 store
    {
        path: 'store',
        name: 'store',
        component: () => import(/* webpackChunkName: "home/store" */ '../views/home/store/index.vue'),
        meta: {
            authkey: ["1-1","1-2","1-3","1-4","10-2","10-3","10-4"]
        }
    },
]

homeRouter.push({
    //没有匹配到的路由都进入404页面
    path: 'noPermission',
    name: 'noPermission',
    component: () => import(/* webpackChunkName: "home/errorPage/noPermission" */ '../views/home/errorPage/noPermission.vue'),
});

homeRouter.push({
    //没有匹配到的路由都进入404页面
    path: '*',
    name: 'notFound',
    component: () => import(/* webpackChunkName: "home/errorPage/404" */ '../views/home/errorPage/404.vue'),
});

module.exports = homeRouter;